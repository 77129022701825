import React from 'react';
import { IDoorProps } from '../door/door';
import './form.scss';

interface IProps {
  door: IDoorProps;
  openTerms: () => void;
  onSubmit: (data: { [key: string]: string | boolean }) => void;
}

export const Form = ({ door, onSubmit, openTerms }: IProps) => {
  const submit = (event) => {
    event.preventDefault();
    onSubmit({});
  };

  return (
    <form className="ovl-adventForm" onSubmit={submit}>
      <div className="ovl-adventForm__row -col-2">
        <input type="text" placeholder="Vorname" />
        <input type="text" placeholder="Nachname" />
      </div>

      <div className="ovl-adventForm__row">
        <input type="text" placeholder="E-Mail Adresse" />
      </div>

      <div className="ovl-adventForm__checks">
        <div className="ovl-adventForm__row">
          <label
            htmlFor="ovl-advent-acceptTerms"
            className="ovl-adventForm__checkLabel"
          >
            <input
              type="checkbox"
              name="ovl-advent-acceptTerms"
              id="ovl-advent-acceptTerms"
            />
            <span>
              Ich bin mit den{' '}
              <button type="button" onClick={openTerms}>
                Teilnahmebedingungen
              </button>{' '}
              einverstanden.
            </span>
          </label>
        </div>
        <div className="ovl-adventForm__row">
          <label
            htmlFor="ovl-advent-newsletterOptIn"
            className="ovl-adventForm__checkLabel"
          >
            <input
              type="checkbox"
              name="ovl-advent-newsletterOptIn"
              id="ovl-advent-newsletterOptIn"
            />
            <span>
              Ja, ich möchte den Newsletter erhalten und zu Marketing-Zwecken
              kontaktiert werden.
            </span>
          </label>
        </div>
      </div>

      <div className="ovl-adventForm__submit">
        <button className="ovl-advent__cta">Gutschein erhalten</button>
      </div>
    </form>
  );
};

export default Form;
