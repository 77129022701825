import React from 'react';
import './terms.scss';

interface IProps {
  close: () => void;
}

export const Terms = ({ close }: IProps) => {
  return (
    <div className="ovl-adventTerms">
      <div>
        <h2>Teilnahmebedingungen</h2>
        <p>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Laudantium
          fugiat reiciendis aliquid autem illo. Mollitia iusto repellat officiis
          a nobis quae eaque voluptate error. Doloremque, facilis. Praesentium
          libero consectetur expedita! Lorem ipsum dolor, sit amet consectetur
          adipisicing elit. Laudantium fugiat reiciendis aliquid autem illo.
          Mollitia iusto repellat officiis a nobis quae eaque voluptate error.
          Doloremque, facilis. Praesentium libero consectetur expedita!
        </p>
        <p>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Laudantium
          fugiat reiciendis aliquid autem illo. Mollitia iusto repellat officiis
          a nobis quae eaque voluptate error. Doloremque, facilis. Praesentium
          libero consectetur expedita! Lorem ipsum dolor, sit amet consectetur
          adipisicing elit. Laudantium fugiat reiciendis aliquid autem illo.
          Mollitia iusto repellat officiis a nobis quae eaque voluptate error.
          Doloremque, facilis. Praesentium libero consectetur expedita! Lorem
          ipsum dolor, sit amet consectetur adipisicing elit. Laudantium fugiat
          reiciendis aliquid autem illo. Mollitia iusto repellat officiis a
          nobis quae eaque voluptate error. Doloremque, facilis. Praesentium
          libero consectetur expedita! Lorem ipsum dolor, sit amet consectetur
          adipisicing elit. Laudantium fugiat reiciendis aliquid autem illo.
          Mollitia iusto repellat officiis a nobis quae eaque voluptate error.
          Doloremque, facilis. Praesentium libero consectetur expedita! Lorem
          ipsum dolor, sit amet consectetur adipisicing elit. Laudantium fugiat
          reiciendis aliquid autem illo. Mollitia iusto repellat officiis a
          nobis quae eaque voluptate error. Doloremque, facilis. Praesentium
          libero consectetur expedita!
        </p>
        <p>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Laudantium
          fugiat reiciendis aliquid autem illo. Mollitia iusto repellat officiis
          a nobis quae eaque voluptate error. Doloremque, facilis. Praesentium
          libero consectetur expedita! Lorem ipsum dolor, sit amet consectetur
          adipisicing elit. Laudantium fugiat reiciendis aliquid autem illo.
          Mollitia iusto repellat officiis a nobis quae eaque voluptate error.
          Doloremque, facilis. Praesentium libero consectetur expedita! Lorem
          ipsum dolor, sit amet consectetur adipisicing elit. Laudantium fugiat
          reiciendis aliquid autem illo. Mollitia iusto repellat officiis a
          nobis quae eaque voluptate error. Doloremque, facilis. Praesentium
          libero consectetur expedita! Lorem ipsum dolor, sit amet consectetur
          adipisicing elit. Laudantium fugiat reiciendis aliquid autem illo.
          Mollitia iusto repellat officiis a nobis quae eaque voluptate error.
          Doloremque, facilis. Praesentium libero consectetur expedita!
        </p>

        <div className="ovl-adventTerms__close">
          <button onClick={close}>Zurück zum Kalender</button>
        </div>
      </div>
    </div>
  );
};

export default Terms;
