import React from 'react';
import './toggle-cap.scss';

interface IProps {
  setShowCalendar: (show: boolean) => void;
}

export const ToggleCap = ({ setShowCalendar }: IProps) => {
  return (
    <button className="ovl-adventToggle" onClick={() => setShowCalendar(true)}>
      <span className="ovl-adventToggle__label">Adventkalender</span>
      <span className="ovl-adventToggle__cap" />
    </button>
  );
};

export default ToggleCap;
