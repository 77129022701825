import React, { useEffect, useState } from 'react';
import './app.scss';
import Calendar from './components/calendar/calendar';
import { IDoorProps } from './components/calendar/door/door';
import ToggleCap from './components/toggle-cap/toggle-cap';

export interface ILocalInfo {
  openedDoors: string[];
  claimedPrizes: string[];
}

export interface ICalendarConfig {
  doors: IDoorProps[];
}

const LOCAL_STORAGE_KEY = 'ovl-advent-calendar-info';

const setLocalInfo = (info: ILocalInfo) => {
  try {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(info));
  } catch (error) {}
};

const getLocalInfo = (): ILocalInfo | null => {
  try {
    const value = localStorage.getItem(LOCAL_STORAGE_KEY);
    return value ? JSON.parse(value) : null;
  } catch (error) {
    return null;
  }
};

// TODO: get this from some JSON or API
const calendarConfig: ICalendarConfig = {
  doors: [
    { id: '1', number: 3, size: 'medium', topSpace: 'medium' },
    { id: '2', number: 22, size: 'small' },
    { id: '3', number: 10, size: 'medium' },
    { id: '4', number: 17, size: 'small' },
    { id: '5', number: 19, size: 'medium' },
    { id: '6', number: 15, size: 'small' },
    { id: '7', number: 12, size: 'large' },
    { id: '8', number: 9, size: 'small' },
    { id: '9', number: 5, size: 'small' },
    { id: '10', number: 8, size: 'medium' },
    { id: '11', number: 21, size: 'small' },
    { id: '12', number: 24, size: 'large' },
    { id: '13', number: 20, size: 'medium' },
    { id: '14', number: 1, size: 'small' },
    { id: '15', number: 16, size: 'medium' },
    { id: '16', number: 2, size: 'large' },
    { id: '17', number: 14, size: 'small' },
    { id: '18', number: 13, size: 'small' },
    { id: '19', number: 7, size: 'medium' },
    { id: '20', number: 23, size: 'large' },
    { id: '21', number: 18, size: 'medium' },
    { id: '22', number: 4, size: 'large' },
    { id: '23', number: 11, size: 'medium' },
    { id: '24', number: 6, size: 'small' },
  ],
};

export const App = () => {
  const localInfo = getLocalInfo();
  const [showCalendar, setShowCalendar] = useState(false);
  const [openedDoors, setOpenedDoors] = useState<string[]>([]);
  const [claimedPrizes, setClaimedPrizes] = useState<string[]>([]);

  useEffect(() => {
    setOpenedDoors(localInfo?.openedDoors ?? []);
    setClaimedPrizes(localInfo?.claimedPrizes ?? []);
  }, [localInfo]);

  const onDoorOpen = (doorId: string) => {
    const updatedOpenDoors = localInfo?.openedDoors
      ? [...localInfo.openedDoors, doorId].filter(
          (value, index, self) => self.indexOf(value) === index
        )
      : [doorId];

    setLocalInfo({
      ...localInfo,
      openedDoors: updatedOpenDoors,
    });

    setOpenedDoors(updatedOpenDoors);
  };

  const onClaimPrize = (doorId: string) => {
    console.log('Claimed prize', doorId);

    const updatedClaimedPrizes = localInfo?.claimedPrizes
      ? [...localInfo.claimedPrizes, doorId].filter(
          (value, index, self) => self.indexOf(value) === index
        )
      : [doorId];

    setLocalInfo({
      ...localInfo,
      claimedPrizes: updatedClaimedPrizes,
    });

    setClaimedPrizes(updatedClaimedPrizes);
  };

  const doors: IDoorProps[] = calendarConfig.doors.map((door) => {
    return {
      ...door,
      wasOpened: openedDoors?.includes(door.id) ?? false,
      prizeClaimed: claimedPrizes?.includes(door.id) ?? false,
    };
  });

  return (
    <div className="ovl-advent">
      {/* <Toggle setShowCalendar={setShowCalendar} /> */}
      <ToggleCap setShowCalendar={setShowCalendar} />

      {showCalendar && (
        <Calendar
          doors={doors}
          onDoorOpen={onDoorOpen}
          onClaimPrize={onClaimPrize}
          setShowCalendar={setShowCalendar}
        />
      )}
    </div>
  );
};

export default App;
