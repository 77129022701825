import React, { useEffect, useState } from 'react';
import prizeImgClaimed from '../../../../assets/prize-claimed.jpg';
import prizeImg from '../../../../assets/prize.jpg';
import { IDoorProps } from '../door/door';
import Form from '../form/form';
import { ReactComponent as CopyIcon } from './copy.svg';
import './door-content.scss';

interface IProps {
  door: IDoorProps;
  onClaimPrize: (doorId: string) => void;
  openTerms: () => void;
  close: () => void;
}

export const DoorContent = ({
  door,
  onClaimPrize,
  close,
  openTerms,
}: IProps) => {
  const [animateIn, setAnimateIn] = useState(false);
  const [showCopyHint, setShowCopyHint] = useState(false);
  const { prizeClaimed } = door;

  useEffect(() => {
    setAnimateIn(true);
  }, []);

  const onSubmitForm = (data: { [key: string]: string | boolean }) => {
    console.log(data);

    // TODO: add api call and store form fields in local storage to pre fill fields on next visit
    onClaimPrize(door.id);
  };

  const handleCodeCopy = (code: string) => {
    try {
      navigator.clipboard.writeText(code);
      setShowCopyHint(true);

      setTimeout(() => {
        setShowCopyHint(false);
      }, 1000);
    } catch (error) {}
  };

  return (
    <div
      className="ovl-adventDoorContent"
      style={{ opacity: animateIn ? 1 : 0 }}
    >
      <div className="ovl-adventDoorContent__img">
        {!prizeClaimed && (
          <img src={prizeImg} alt={`Bild von Tür #${door.number}`} />
        )}
        {prizeClaimed && (
          <img src={prizeImgClaimed} alt={`Bild von Tür #${door.number}`} />
        )}
        <div className="ovl-adventDoorContent__imgDesc">
          {door.number}. Dezember
        </div>
      </div>

      <div className="ovl-adventDoorContent__body">
        <h2>Ein Kaffee bei Overlap</h2>

        {!prizeClaimed && (
          <>
            <p>
              Wir möchten Sie gerne auf einen Kaffee in unserem Büro einladen.
              Machen Sie jetzt mit und erhalten Sie einen Gutscheincode!
            </p>

            <Form door={door} onSubmit={onSubmitForm} openTerms={openTerms} />
          </>
        )}

        {prizeClaimed && (
          <>
            <p>
              Wir freuen uns schon auf Ihren Besuch. Hier ist Ihr Gutscheincode:
            </p>

            <div className="ovl-adventDoorContent__code">
              <div className="ovl-adventDoorContent__codeField">
                <div>ABC-123-COFFEE</div>
                <small style={{ opacity: showCopyHint ? 1 : 0 }}>
                  In Zwischenablage kopiert!
                </small>

                <button onClick={() => handleCodeCopy('ABC-123-SHOP')}>
                  <CopyIcon />
                </button>
              </div>
            </div>

            <p>
              <small>
                Sie sind schon gespannt, was es morgen Tolles im Adventkalender
                geben könnte? Entdecken Sie noch viel mehr in unserem Shop.
              </small>
            </p>

            <div className="ovl-adventDoorContent__cta">
              <a
                className="ovl-advent__cta"
                href="https://overlap.at"
                target="_blank"
              >
                Shop Entdecken
              </a>
            </div>
          </>
        )}

        <div className="ovl-adventDoorContent__close">
          <button onClick={close}>Zurück zum Kalender</button>
        </div>
      </div>
    </div>
  );
};

export default DoorContent;
