import React from 'react';
import './footer.scss';

interface IProps {
  showTerms: boolean;
  setShowTerms: (show: boolean) => void;
}

export const Footer = ({ showTerms, setShowTerms }: IProps) => {
  return (
    <div className="ovl-adventCalendarFooter">
      <button
        className="ovl-adventCalendarFooter__legalNote"
        onClick={() => setShowTerms(!showTerms)}
      >
        {showTerms ? 'Zurück zum Kalender' : 'Zu den Teilnahmebedingungen'}
      </button>

      <a
        href="https://overlap.at"
        target="_blank"
        rel="noopener"
        className="ovl-adventCalendarFooter__copyright"
      >
        Copyright 2020 - Overlap GmbH & Co KG
      </a>
    </div>
  );
};

export default Footer;
