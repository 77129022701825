import css from 'classnames';
import React from 'react';
import './door.scss';

export type IDoorSize = 'small' | 'medium' | 'large';

export type IDoorSpacing = 'small' | 'medium' | 'large';

export interface IDoorProps {
  id: string;
  number: number;
  size?: IDoorSize;
  topSpace?: IDoorSpacing;
  wasOpened?: boolean;
  prizeClaimed?: boolean;
}

interface IProps {
  door: IDoorProps;
  animating: string;
  doAnimate: (doorId: string) => void;
  openDoor: (doorId: string) => void;
}

export const Door = ({ door, openDoor, animating, doAnimate }: IProps) => {
  const fadeOutBox = !!animating && animating !== door.id;
  const fadeDoorContent =
    door.prizeClaimed || (!!animating && animating === door.id);

  const onDoorClicked = () => {
    if (door.prizeClaimed) {
      openDoor(door.id);
    } else {
      doAnimate(door.id);

      // The timeout matches the animation time defined for CSS transitions
      setTimeout(() => {
        openDoor(door.id);
      }, 1500);
    }
  };

  return (
    <div
      className={css('ovl-adventDoor', {
        '-small': door.size === 'small',
        '-medium': door.size === 'medium',
        '-large': door.size === 'large',

        '-topSpaceSmall': door.topSpace === 'small',
        '-topSpaceMedium': door.topSpace === 'medium',
        '-topSpaceLarge': door.topSpace === 'large',
      })}
    >
      <div
        className="ovl-adventDoor__box"
        style={{ opacity: fadeOutBox ? 0 : 1 }}
      >
        <button className="ovl-adventDoor__trigger" onClick={onDoorClicked}>
          <span
            className="ovl-adventDoor__opened"
            style={{
              opacity: fadeDoorContent ? 1 : 0,
              backgroundImage: 'url(../../../assets/prize.jpg)',
            }}
          ></span>

          <span
            className="ovl-adventDoor__closed"
            style={{ opacity: fadeDoorContent ? 0 : 1 }}
          >
            <span>{door.number}</span>
          </span>
        </button>
      </div>
    </div>
  );
};

export default Door;
